.img {
  display: block;
  &_about {
    margin: 24px auto;
    width: 100%;
    @include md {
      max-width: 332px;
    }
  }
  &_project {
    margin: 0 auto;
    width: 100%;
    max-width: 600px;
    opacity: 0.4;
    @include sm {
      margin: unset;
      max-width: 470px;
      z-index: -1;
    }
  }
}
