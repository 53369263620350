*,
*::before,
*::after {
  @include md {
    cursor: none;
  }
}

.cursor {
  display: none;
}
@include md {
  .cursor {
    display: block;
    z-index: 100;
    border-radius: 50%;
    mix-blend-mode: difference;
    position: fixed;
    pointer-events: none;
    &-dot {
      top: -3.5px;
      left: -3.5px;
      width: 7px;
      height: 7px;
      background-color: $c-green;
    }
    &-circle {
      top: -15px;
      left: -15px;
      width: 30px;
      height: 30px;
      border: 2px solid $c-green;
    }
  }
}

.wrapper_flyimg {
  display: none;
  pointer-events: none;

  @include md {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;

    // opacity: 0.5;
    img {
      display: block;
      transform: translate(-70%, -60%);
    }
  }
}

.section_loader {
  position: fixed;
  top: 0;
  left: 0;
  background-color: $c-grey;
  width: 100vw;
  height: 100vh;
}
.wrapper_loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loader {
  width: 100px;
  height: 100px;
  border: 1.5em solid currentcolor;
  border-top-color: transparent;
  border-radius: 50%;
  animation: 1.5s loader linear infinite;
  position: absolute;
  opacity: 0.4;
  &::before {
    content: "";
    width: 88px;
    height: 88px;
    border: 0.375em solid currentcolor;
    border-bottom-color: transparent;
    border-radius: 50%;
    position: absolute;
    top: -24px;
    left: -24px;
    animation: 3s loader-3 linear reverse infinite;
    opacity: 0.9;
  }
  &::after {
    content: "";
    width: 94px;
    height: 94px;
    border: 0.1875em solid currentcolor;
    border-bottom-color: transparent;
    border-radius: 50%;
    position: absolute;
    top: -24px;
    left: -24px;
    opacity: 0.7;
    animation: 3s loader-2 linear infinite;
  }
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes loader-2 {
  0% {
    transform: scale(0.9) rotate(0deg);
  }
  100% {
    transform: scale(0.9) rotate(360deg);
  }
}

@keyframes loader-3 {
  0% {
    transform: scale(0.7) rotate(0deg);
  }
  100% {
    transform: scale(0.7) rotate(360deg);
  }
}

.link {
  &_header {
    position: relative;
    height: max-content;
    &::after {
      content: "";
      display: block;
      background-color: #60ffc6;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0%;
      height: 2px;
      transition: ease 0.4s;
      will-change: width;
    }
    &:hover {
      &::after {
        width: 100%;
      }
    }
  }

  &_project {
    transition: ease 0.4s;
    will-change: padding;
    &:hover {
      padding-right: 30px;
    }
  }

  &_page-project {
    position: relative;
    &::after {
      left: 0px;
      transition: ease 0.4s;
    }
    &::before {
      content: "";
      display: block;
      position: absolute;
      bottom: -2px;
      width: 0;
      height: 2px;
      background-color: $c-green;
      transition: ease 0.4s;
    }
    &:hover {
      &::before {
        width: 89%;
      }
      &::after {
        left: 0.5rem;
      }
    }
  }
}

.text_big {
  &::before {
    height: 0%;
    animation: bigTextBefore 0.5s ease-in 1s 1 forwards;
  }
  .last_word {
    &::after {
      width: 0%;
      animation: textSpan 0.7s ease-in 2s 1 forwards;
    }
  }
}
@keyframes textSpan {
  0% {
    width: 0%;
  }
  100% {
    width: 85%;
  }
}
@keyframes bigTextBefore {
  0% {
    height: 0%;
  }
  100% {
    height: 73%;
  }
}
