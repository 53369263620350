@font-face {
  font-family: "Poppins";
  src: url("/fonts/Poppins-Regular.woff2") format("woff2");
}
@font-face {
  font-family: "Poppins-Bold";
  src: url("/fonts/Poppins-Bold.woff2") format("woff2");
  font-weight: bold;
}
@font-face {
  font-family: "IBM Plex Sans";
  src: url("/fonts/IBMPlexSans-Regular.woff2") format("woff2");
}

$c-green: #60ffc6;
$c-grey: #2b2b2b;
$f-pop: "Poppins", sans-serif;
$f-pobB: "Poppins-Bold", sans-serif;
$f-ibm: "IBM Plex Sans", sans-serif;
$screen-sm: 600px;
$screen-md: 800px;
$screen-lg: 1000px;
$screen-xl: 1200px;
$screen-custom: 1100px;
