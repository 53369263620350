html {
  scroll-behavior: smooth;
}
body {
  background-color: $c-grey;
  padding: 26px;
  padding-bottom: 0;

  @include md {
    padding: 52px;
    padding-bottom: 0;
  }
}
#root {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  min-height: 93vh;
  @include custom {
    max-width: 1152px;
    margin: 0 auto;
  }
}
.hidden {
  display: none;
}

.menu {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.title {
  margin: 52px 0;
  @include md {
    margin-top: 128px;
    margin-bottom: 64px;
    // margin: 128px 0;
  }
  &_menu {
    margin: 0;
  }
  &_project {
    margin-top: 64px;
  }
  &_tech-list {
    margin-top: 52px;
    margin-bottom: 8px;
  }
}

.text_big {
  max-width: 332px;

  @include md {
    max-width: 600px;
    margin: 0 auto;
    position: relative;

    &::before {
      content: "";
      display: block;
      position: absolute;
      left: -10px;
      bottom: 42px;
      width: 1px;
      background-color: $c-green;
    }
  }
  .text_wrapper {
    overflow: hidden;
    display: inline-block;
    margin-right: 1rem;
    .word {
      white-space: nowrap;
      display: block;
    }
  }

  .last_word {
    position: relative;
    height: 100px;
    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: 32px;
      left: 4px;
      height: 25px;
      background-color: $c-green;
      opacity: 0.25;
      box-shadow: 5px 8px 11px 0px rgb(0 0 0 / 60%);
      transition: 1s 1s ease-in;
    }
  }
}

.wrapper_about {
  @include md {
    display: flex;
    flex-flow: row nowrap;
    gap: 32px;
    margin: 0 auto;
    width: max-content;
  }
}
.text_about {
  max-width: 399px;
}
.text_project {
  max-width: 399px;
  min-height: 150px;
}
.separator {
  display: block;
  width: 1px;
  height: 104px;
  background-color: $c-green;
  margin: 0 auto;
}

.section {
  max-width: 390px;
  margin: 0 auto;
  @include md {
    max-width: none;
  }

  &_text-wrapper {
    @include md {
      margin: 186px auto;
    }
  }

  &_page-project {
    @include sm {
      min-width: 663px;
      position: relative;
    }
  }
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
  &_project {
    margin-bottom: 52px;
    @include md {
      width: 70%;
      margin-left: auto;
    }
  }
  &_tech {
    display: flex;
    flex-flow: column nowrap;
    position: relative;
    left: -1rem;
    min-height: 110px;
    p {
      margin: 0;
    }
  }
}
.project_list {
  position: relative;

  display: flex;
  justify-content: flex-end;
  z-index: 3;
  &:nth-child(1) {
    &::before {
      content: "";
      position: absolute;
      background-color: $c-green;
      top: 0;
      width: 100%;
      height: 1px;
    }
  }
  &::after {
    content: "";
    position: absolute;
    background-color: $c-green;
    bottom: 0;
    width: 100%;
    height: 1px;
  }
}

.link {
  &_project {
    padding: 16px 0;
    padding-right: 11px;
    text-align: end;
    width: 100%;
  }
  &_page-project {
    &::after {
      content: " >";
      position: relative;
      top: 1px;
    }
  }
}
.wrapper_project {
  display: flex;
  flex-flow: column nowrap;
  gap: 32px;
  align-items: flex-end;
  @include sm {
    position: absolute;
    bottom: 80px;
    right: 0;
    margin-left: auto;
    width: max-content;
  }
}
.wrapper_link {
  position: relative;
  padding: 16px 0;
  width: 100%;
}

.footer {
  border-top: 2px solid rgba(96, 255, 198, 0.5);
  margin-top: 52px;
  padding: 12px 0;
  p {
    opacity: 0.5;
    margin: 0;
  }
}
