@mixin sm {
  @media (min-width: #{$screen-sm}) {
    @content;
  }
}
@mixin md {
  @media (min-width: #{$screen-md}) {
    @content;
  }
}
@mixin lg {
  @media (min-width: #{$screen-lg}) {
    @content;
  }
}
@mixin xl {
  @media (min-width: #{$screen-xl}) {
    @content;
  }
}

@mixin custom {
  @media (min-width: #{$screen-custom}) {
    @content;
  }
}
