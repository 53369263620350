html {
  @include md {
    font-size: 106.3%;
  }
  color: $c-green;
}
body {
  font-family: $f-pop;
}
.title {
  font-family: $f-pobB;
  font-size: 2.827rem;
  @include md {
    font-size: 3.998rem;
  }
  &_menu {
    font-size: 1.414rem;
    margin: 0;
  }
  &_big {
    font-size: 3.998rem;
  }
  &_tech-list {
    font-size: 1rem;
  }
}

.link {
  color: $c-green;
  text-decoration: none;
  &_title {
    font-family: $f-pop;
    font-size: 1.414rem;

    line-height: 24px;
  }
  &_project {
    font-family: $f-ibm;
    font-size: 2.827rem;
    white-space: nowrap;
    opacity: 0.75;
  }
}

.list {
  &_tech {
    &:nth-child(0) {
      font-weight: 600;
    }
  }
}
p {
  line-height: 36.4px;
}

.text_big {
  font-family: $f-pobB;
  font-size: 3.998rem;
  line-height: 68px;
}

footer {
  p {
    font-size: 0.707rem;
    line-height: normal;
  }
}
